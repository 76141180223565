import React from "react"
import ArticleThumb from "../components/articleThumb"
import { Grid } from "theme-ui"
import { getArticlePath } from "../utils/path"

const ArticlesList = ({ articles }) => {
  return (
    <Grid columns={[1, 2, 2, 4]} gap={[16, 32]}>
      {articles.map(article => (
        <ArticleThumb article={article} getPath={getArticlePath} key={article.id} />
      ))}
    </Grid>
  )
}

export default ArticlesList
