import React from "react"
import { Flex } from "@theme-ui/components"
import { Link } from "../components/link"
import { i18nContext } from "../context/i18nContext"
import { ArrowLeft, ArrowRight } from "react-feather"

// Begin swiper
import SwiperCore, { Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
SwiperCore.use([Mousewheel, Navigation, A11y])
// End swiper

const CategoriesList = ({ categories, getRootPath, getCategoryPath }) => {
  return (
    <Flex
      sx={{
        mb: 4,
        position: "relative",
        ".swiper-slide": {
          width: "auto",
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <Flex
        as="div"
        sx={{
          color: "primary",
          alignItems: "center",
          border: "none",
          background: "none",
          cursor: "pointer",
          minWidth: "32px",
          minHeight: "32px",
          padding: "1px 6px",
          "&.swiper-button-disabled": {
            opacity: 0.4,
          },
        }}
        className="prev-collection-stories"
      >
        <ArrowLeft size={32} />
      </Flex>
      <Swiper
        spaceBetween={0}
        slidesPerView={"auto"}
        navigation={{
          nextEl: ".next-collection-stories",
          prevEl: ".prev-collection-stories",
        }}
        watchOverflow
        watchOverflow
      >
        <SwiperSlide>
          <i18nContext.Consumer>
            {t => (
              <Link href={getRootPath(t.locale)} variant="tab">
                {t.all}
              </Link>
            )}
          </i18nContext.Consumer>
        </SwiperSlide>
        {categories.map(category => (
          <SwiperSlide key={category.originalId}>
            <Link
              to={getCategoryPath(category, category.locale)}
              key={category.id}
              variant="tab"
              sx={{}}
            >
              {category.title}
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <Flex
        as="div"
        sx={{
          marginLeft: "0.25rem",
          color: "primary",
          alignItems: "center",
          border: "none",
          background: "none",
          cursor: "pointer",
          "&.swiper-button-disabled": {
            opacity: 0.4,
          },
          minWidth: "32px",
          minHeight: "32px",
          padding: "1px 6px",
        }}
        className="next-collection-stories"
      >
        <ArrowRight size={32} />
      </Flex>
    </Flex>
  )
}

export default CategoriesList
