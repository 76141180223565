import React from "react"
import { Text } from "@theme-ui/components"

const BlogTitle = ({ page }) => {
  return (
    <Text dir="invalid" variant="h1" as="h1" sx={{ mb: [4, 5], color: "primary" }}>
      {page.title}
    </Text>
  )
}

export default BlogTitle
